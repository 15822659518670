<template>
  <a-card>
    <template #header>
      <div class="flex flex-col lg:flex-row justify-between space-y-4 lg:space-y-0 px-2 py-2 border-b border-gray-200 sm:px-2">
        <h3 class="text-lg leading-6 font-medium text-gray-900 mt-1">
          <a-badge class="bg-red-600 text-white mr-2 align-middle">
            LIVE
          </a-badge>
          {{ meeting.title }}
        </h3>
        <div class="flex-shrink-0">
          <panelists
            v-if="hasPanelists"
            :meeting="meeting"
          />
          <attendees
            v-if="showAttendees"
            :meeting="meeting"
            :max="3"
          />
        </div>
      </div>
    </template>
    <iframe
      v-if="loaded"
      :src="src"
      width="100%"
      height="500px"
    />
    <div
      v-else
      class="h-112 bg-black text-white"
    >
      <div
        class="h-full w-full flex items-center justify-center" 
      >
        <div>
          <a-button
            type="primary"
            @click="onPlay"
          >
            <a-icon
              type="fad"
              name="play-circle"
              class="mr-2"
              size="lg"
            />  Join Meeting
          </a-button>
        </div>
      </div>
    </div>
  </a-card>
</template>

<script>
import { get, isEmpty } from '@/utils/lodash';
import Attendees from '@/components/meetings/card/attendees/attendees.vue';
import Panelists from '@/components/meetings/card/panelists/panelists.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Attendees,
    Panelists
  },
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loaded: false
  }),
  computed: {
    ...mapGetters('auth', ['hash']),
    ...mapGetters('conferences', ['current']),
    meetingHash() {
      return get(this.meeting, 'meeting.meetingIdHash');
    },
    src() {
      return (
        process.env.VUE_APP_API_ROUTE +
        `/conferences/${this.current}/streams/${this.meetingHash}/${this.hash}`
      );
    },
    type() {
      return get(this.meeting, 'type.key');
    },
    isSeminar() {
      return this.type === 'seminar';
    },
    attendees() {
      return get(this.meeting, 'attendees');
    },
    showAttendees() {
      return !this.isSeminar && !isEmpty(this.attendees);
    },
    hasPanelists() {
      return this.isSeminar && !isEmpty(get(this.meeting, 'panelists'));
    },
    hasDescription() {
      return !isEmpty(this.meeting.description);
    }
  },
  methods: {
    onPlay() {
      this.loaded = true;
    }
  }
};
</script>