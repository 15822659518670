<template>
  <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
    <stream-card
      v-for="meeting in streams"
      :key="meeting.id"
      :meeting="meeting"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import StreamCard from '@/components/streams/card/card.vue';
import now from '@/utils/now';
export default {
  components: {
    StreamCard
  },
  data: () => ({
    scheduleInterval: null
  }),
  computed: {
    ...mapGetters('conferences', ['current']),
    ...mapState('streams', ['streams', 'loaded'])
  },
  watch: {
    current: {
      handler: 'onCurrentConferenceChange'
    }
  },
  created() {
    this.onCurrentConferenceChange();
    this.setupInterval();
  },
  beforeUnmount() {
    this.clear();
    this.clearInterval();
  },
  methods: {
    ...mapActions('streams', ['all']),
    ...mapMutations('streams', { clear: 'CLEAR_STREAMS' }),
    setupInterval() {
      this.scheduleInterval = setInterval(() => this.fetch(), 30000);
    },
    clearInterval() {
      clearInterval(this.scheduleInterval);
    },
    async fetch() {
      await this.all(now.value);
    },
    async onCurrentConferenceChange() {
      if (this.current) {
        await this.all(now.value);
      }
    }
  }
};
</script>